import React, { Fragment, useCallback, useEffect, useState } from 'react';
import http from 'Utils/http';
import Editor from 'react-simple-code-editor';
import {
    Box,
    Button,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    ListSubheader,
    Select,
    TextField,
    Typography,
    FormHelperText, IconButton
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { setLoading } from 'Actions/loading';
import { CustomSnackbar } from 'Shared/CustomSnackbar';
import { useCreateUrl } from 'Hooks/useCreateUrl';
import { setFlowMethodTransformationList } from 'Actions/flowMethodList';
import { highlight, languages } from 'prismjs/components/prism-core';
import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css';

const FlowTransformation = ({
                                id,
                                indexChain,
                                sourceId,
                                previousId,
                                transformBy,
                                isHiddenField,
                                presetOperation,
                                transformValue,
                                canShowChainRules,
                                selectTransformation = 'FIELD',
                                defaultTitle
                            }) => {
    const dispatch = useDispatch();
    const { createUrl } = useCreateUrl();
    const { enqueueSnackbar } = useSnackbar();

    const apiListRedux = useSelector((state) => state.APIs.APIs);
    const flowMethods = useSelector((state) => state.flowMethodList.flowMethodList);
    const operatorsList = useSelector((state) => state.operators.operators);
    const transformations = useSelector((state) => state.flowMethodList.transformationList);
    const title = useSelector((state) => state.matchModal.content.title);
    const currentUserId = useSelector((state) => state.auth.user.id);

    const [apis, setApis] = useState([]);
    const [methods, setMethods] = useState([]);
    const [fields, setFields] = useState([]);
    const [isSingle, setIsSingle] = useState(false);
    const [dropDown, setDropDown] = useState(false);
    const [hasExecuted, setHasExecuted] = useState(false);
    const [code, setCode] = useState('');
    const [methodDefault, setMethodDefault] = useState('');
    const [fieldDefault, setFieldDefault] = useState('');
    const [operatorDescription, setOperatorDescription] = useState('');
    const [visibleTransformation, setVisibleTransformation] = useState(selectTransformation);

    const {
        register,
        watch,
        control,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        defaultValues: {
            api: '',
            method: '',
            field: '',
            select_transformation: selectTransformation,
            transform_by: transformBy ? transformBy : '',
            transform_value: transformValue ? transformValue : ''
        },
        mode: 'onChange',
    });

    const renderOperatorDescription = useCallback((transform_by, select_transformation, transform_value, field = null) => {
        const operator = operatorsList?.other?.find(item => item.id === transform_by);
        setIsSingle(operator?.single);
        if (operator && operator?.description) {
            let operatorDescription = operator.description;

            if (indexChain === 0) {
                operatorDescription = operatorDescription.replace('{{a}}', `field <span style="color: #1BBC6F">${defaultTitle}</span>`);
            } else {
                operatorDescription = operatorDescription.replace('{{a}}', `<strong>[result of previous Chain]</strong>`);
            }

            if (select_transformation === 'CONSTANT' && transform_value) {
                operatorDescription = operatorDescription.replace('{{b}}', `<span style="color: #1BBC6F">${transform_value}</span>`);
            } else if (select_transformation === 'FIELD' && field) {
                const foundField = fields.find(item => item.id === field);
                operatorDescription = foundField ? operatorDescription.replace('{{b}}', `<span style="color: #1BBC6F">${foundField.name}</span>`) : '';
            } else if (select_transformation === 'OBJECT' && transform_value) {
                operatorDescription = operatorDescription.replace('{{b}}', `<strong>user defined value</strong>`);
            } else {
                operatorDescription = operatorDescription.replace('{{b}}', `<strong>constant or field (please choose operand type)</strong>`);
            }

            setOperatorDescription(operatorDescription);
        } else {
            setOperatorDescription('');
        }
    }, [operatorsList, indexChain, defaultTitle, fields, setOperatorDescription, setIsSingle]);

    const isValidJSON = (jsonString) => {
        try {
            JSON.parse(jsonString);
            return true;
        } catch (error) {
            return false;
        }
    }

    const handleError = useCallback((err) => {
        dispatch(setLoading(false));
        enqueueSnackbar(`${err.response.data.message}`, {
            action: CustomSnackbar,
            variant: 'error',
        });
    }, [dispatch, enqueueSnackbar]);

    // Call only once with component initialization
    const getDefaultRequest = useCallback(async () => {
        if (previousId && sourceId) {
            try {
                const res = await http.get(`/user/field/${sourceId}/get`);
                const method = res.data.field.method;
                const integration = method.integration;

                setValue('api', integration.id);
                setMethodDefault(method.id);
                setFieldDefault(sourceId);

            } catch (error) {
                handleError(error);
            }
        }
        renderOperatorDescription(transformBy, selectTransformation, transformValue);
    }, [previousId, sourceId, setValue, setMethodDefault, setFieldDefault, handleError, selectTransformation, transformBy, transformValue, renderOperatorDescription]);

    useEffect(() => {
        // Update code state based on selectTransformation
        if (selectTransformation === 'OBJECT') {
            setCode(transformValue);
        } else {
            setCode('');
        }
    }, [selectTransformation, transformValue]);

    // Get default values for transformation with init component
    useEffect(() => {
        if (!hasExecuted) {
            getDefaultRequest().then(r => {
                setHasExecuted(true);
            });
        }
    }, [hasExecuted, getDefaultRequest]);

    useEffect(() => {
        if (flowMethods.length !== 0) {
            const uniqueIntegrationIds = [...new Set(flowMethods.map(item => item.method.integration_id))];
            setApis(apiListRedux.filter(api => uniqueIntegrationIds.includes(api.id)));
        }
    }, [flowMethods, apiListRedux]);

    useEffect(() => {
        const subscription = watch((values, { name }) => {
            const { api, method, select_transformation, transform_by, field, transform_value } = values;
            switch (name) {
                case 'api':
                    if (api) {
                        const filteredMappedOutputs = flowMethods
                            .filter(item => item.method.integration_id === api)
                            .map(({ id, method, name }) => {
                                return {
                                    ...method,
                                    flow_method_id: id,
                                    name: name ? name : method.name
                                }
                            })
                            .filter(item => item.name !== 'Output Port');
                        setMethods(filteredMappedOutputs);
                        setFields([]);
                        setValue('method', '');
                        setValue('field', '');
                    }
                    break;

                case 'method':
                    if (method) {
                        // const getFlowMethod = methods.find(item => item.id === method);
                        fetchFlowMethod(method);
                    }
                    break;

                case 'select_transformation':
                    setVisibleTransformation(select_transformation);
                    break;
                case 'transform_by':
                    if (operatorsList?.other) {
                        const transform = operatorsList.other.find(item => item.id === transform_by);
                        if (transform) {
                            setIsSingle(transform?.single);
                            canShowChainRules(transform.operation !== 'with');
                        }
                    }
                    break;
                default:
                    break;
            }

            renderOperatorDescription(transform_by, select_transformation, transform_value, field);
        });

        const fetchFlowMethod = (flowMethodId) => {
            if (flowMethodId) {
                http.get(createUrl(`/flow/method/${flowMethodId}/get`))
                    .then(({ data }) => {
                        setFields(data.flowMethod?.method?.response.filter(item => item.name !== 'hidden_field_in_input_port') || []);
                    })
                    .catch(() => setFields([]));

                setValue('field', '');
            }
        };

        return () => subscription.unsubscribe();
    }, [watch, createUrl, flowMethods, setValue, methods, operatorsList, renderOperatorDescription, canShowChainRules, setIsSingle]);

    const updateTransformation = (operationId, constantValue) => {
        const params = {
            transformation_id: id,
            source_id: indexChain !== 0 ? sourceId : null
        };

        const updatedParams = Object.assign({}, params, {
            operation_id: operationId,
            constant: constantValue,
        });

        http.post(createUrl(`/transformation/${id}/update`), updatedParams)
            .then(() => {
                enqueueSnackbar('Transformation has been saved', {
                    action: CustomSnackbar,
                    variant: 'success',
                });
            })
            .catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {
                    action: CustomSnackbar,
                    variant: 'error',
                });
            })
            .finally(() => dispatch(setLoading(false)));
    };

    const saveTransformation = () => {
        dispatch(setLoading(true));
        const methodValue = getValues('method');
        const transformByValue = getValues('transform_by');
        const operationIdValue = getValues('operation_id');
        const selectTransformation = getValues('select_transformation');

        if (transformByValue === 'null' && operationIdValue === 'null') {
            updateTransformation(0, 'null');
            return;
        }

        if (transformByValue === 'null') {
            updateTransformation(0, 'null');
            return true;
        }

        if (operationIdValue === 'null') {
            updateTransformation(getValues('transform_by'), getValues('transform_value'));
            return true;
        }

        if (operationIdValue !== 'null' && transformByValue !== 'null') {
            const params = {
                user_id: currentUserId,
                source_id: indexChain !== 0 ? sourceId : null,
                source_flow_method_id: methodValue,
                operation_id: transformByValue,
                constant: getValues('transform_value'),
            };

            if (previousId && indexChain !== 0) {
                params.source_id = getValues('field');
            }

            if (selectTransformation === 'CONSTANT' || selectTransformation === 'OBJECT') {
                params.source_id = null;
                params.source_flow_method_id = null;
            }

            if (selectTransformation === 'FIELD') {
                params.constant = null;
            }

            http.post(createUrl(`/transformation/${id}/update`), params).then((res) => {
                const mappedTransformations = transformations.map((item) => {
                    if (res.data.transformation.id === item.id) {
                        return res.data.transformation;
                    }
                    return item;
                });
                dispatch(setFlowMethodTransformationList(mappedTransformations));
                enqueueSnackbar('Transformation has been saved', {
                    action: CustomSnackbar,
                    variant: 'success',
                });
            }).catch((err) => {
                enqueueSnackbar(`${err.response.data.message}`, {
                    action: CustomSnackbar,
                    variant: 'error',
                });
            }).finally(() => dispatch(setLoading(false)));

            return true;
        }
    };

    // Set default field value with init component
    useEffect(() => {
        if (previousId && fields.length > 0 && fieldDefault) {
            setValue('field', fieldDefault);
            setFieldDefault('');
        }
    }, [previousId, fields, fieldDefault, setValue]);

    // Set default method value with init component
    useEffect(() => {
        if (previousId && methods.length > 0 && methodDefault) {
            const currentMethod = methods.find(item => item.id === methodDefault);
            if (currentMethod) {
                setValue('method', currentMethod.flow_method_id);
                setMethodDefault('');
            }
        }
    }, [previousId, methods, methodDefault, setValue]);

    const menuItems = !isHiddenField || isHiddenField && indexChain !== 0 ? [
        <MenuItem key='no_transformation' value={'null'}>No transformation</MenuItem>
    ] : [];
    Object.entries(operatorsList).forEach(([category, operators]) => {
        if (['logical', 'other', 'output', 'input'].includes(category)) {
            return false;
        }
        if (!isHiddenField || isHiddenField && indexChain !== 0) {
            menuItems.push(
                <ListSubheader key={`subheader-${category}`} sx={{color: '#c5c7c7'}}>
                    <em>{`${category.charAt(0).toUpperCase() + category.slice(1)} Transformations`}</em>
                </ListSubheader>
            );
        }
        operators.sort((a, b) => {
            const operationA = a.operation.toUpperCase();
            const operationB = b.operation.toUpperCase();
            if (operationA < operationB) {
                return -1;
            }
            if (operationA > operationB) {
                return 1;
            }
            return 0;
        }).forEach(({ id, operation }) => {
            if (isHiddenField && indexChain === 0) {
                if (operation === 'set') {
                    menuItems.push(
                        <MenuItem key={`${id}_${category}_no_transformation`} value={id} sx={{marginLeft: '10px'}}>
                            {operation}
                        </MenuItem>
                    );
                }
            } else {
                menuItems.push(
                    <MenuItem key={`${id}_${category}_no_transformation`} value={id} sx={{marginLeft: '10px'}}>
                        {operation}
                    </MenuItem>
                );
            }
        });
    });

    return (
        <Box component="form"
             sx={{
                 marginBottom: '10px',
                 padding: '13px 12px 12px 12px',
                 background: '#e0e3e4',
                 borderRadius: '6px',
             }}
        >
            <Grid container columnSpacing={'10px'}>
                <Grid item xs={12}>
                    <Typography
                        sx={{
                            marginRight: '16px',
                            marginBottom: '16px',
                            fontWeight: '700',
                            letterSpacing: '0.1em',
                            textTransform: 'uppercase',
                            color: 'rgba(65, 77, 101, 0.85)',
                        }}
                    >
                        Transformation
                    </Typography>
                </Grid>
                <Grid item xs={4}>
                    <FormControl sx={{ width: '100%', marginBottom: '12px' }}>
                        <InputLabel>Transform by</InputLabel>
                        <Controller
                            id="transform_by"
                            name="transform_by"
                            control={control}
                            rules={{ required: true }}
                            render={({ field }) => (
                                <Select {...field} label="Transform by">
                                    {menuItems}
                                </Select>
                            )}
                        />
                    </FormControl>
                </Grid>
                {!isSingle ? (<>
                        <Grid item xs={4}>
                            <FormControl sx={{width: '100%', marginBottom: '12px'}}>
                                <InputLabel>Operand type</InputLabel>
                                <Controller
                                    name="select_transformation"
                                    control={control}
                                    rules={{required: true}}
                                    render={({field}) => (
                                        <Select {...field} label="Operand type">
                                            <MenuItem value={'CONSTANT'}>Constant</MenuItem>
                                            {indexChain !== 0 && (<MenuItem value={'FIELD'}>Field</MenuItem>)}
                                            <MenuItem value={'OBJECT'}>Object</MenuItem>
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={4}>
                            {/* transformation field */}
                            {visibleTransformation === 'FIELD' && (<>
                                <FormControl sx={{width: '100%', marginBottom: '16px'}}>
                                    <InputLabel>API</InputLabel>
                                    <Controller
                                        name="api"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <Select {...field} label="API">
                                                {apis.map((item) => (
                                                    <MenuItem key={`${item.id}api`} value={item.id}>
                                                        {item.name} ({item.id})
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                <FormControl sx={{width: '100%', marginBottom: '16px'}}>
                                    <InputLabel>Method</InputLabel>
                                    <Controller
                                        name="method"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <Select {...field} label="Method">
                                                {methods.map((item) => (
                                                    <MenuItem key={`${item.flow_method_id}method`} value={item.flow_method_id}>
                                                        {item.name} ({item.flow_method_id})
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>

                                <FormControl sx={{width: '100%', marginBottom: '16px'}}>
                                    <InputLabel>Field</InputLabel>
                                    <Controller
                                        name="field"
                                        control={control}
                                        rules={{required: true}}
                                        render={({field}) => (
                                            <Select {...field} label="Field">
                                                {fields.map((item) => (
                                                    <MenuItem key={`${item.id}field`} value={item.id}>
                                                        {item.name} ({item.id})
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </>)}

                            {/* transformation constant */}
                            {visibleTransformation === 'CONSTANT' && (<>
                                <FormControl sx={{width: '100%', marginBottom: '12px'}}>
                                    <TextField label="Transformation value" {...register('transform_value', {})} />
                                </FormControl>
                            </>)}
                        </Grid>
                        <Grid item xs={12}>
                            {/* transformation object */}
                            {visibleTransformation === 'OBJECT' && (<>
                                <FormControl sx={{width: '100%', marginBottom: '12px'}}>
                                    <Controller
                                        name="transform_value"
                                        control={control}
                                        rules={{
                                            required: true,
                                            validate: (value) => isValidJSON(value) || 'Invalid JSON format',
                                        }}
                                        render={({field}) => (
                                            <>
                                                <Editor
                                                    {...field}
                                                    value={code}
                                                    onValueChange={code => setCode(code)}
                                                    highlight={code => highlight(code, languages.js)}
                                                    padding={10}
                                                    tabSize={4}
                                                    style={{
                                                        borderRadius: '4px',
                                                        borderColor: 'rgba(0, 0, 0, 0.23)',
                                                        borderWidth: '1px',
                                                        borderStyle: 'solid',
                                                        backgroundColor: '#F2F5F8',
                                                        minHeight: '150px',
                                                    }}
                                                />
                                                {errors.transform_value && <FormHelperText>{errors.transform_value.message}</FormHelperText>}
                                            </>
                                        )}
                                    />

                                </FormControl>
                            </>)}
                        </Grid>
                    </>) : null}
                <Grid item xs={12}>
                    {operatorDescription && (
                        <Box sx={{
                            border: '1px solid rgba(148, 157, 176, 0.25)',
                            borderRadius: '4px',
                            padding: '10px',
                            backgroundColor: '#e8ecf0',
                            marginBottom: '10px'
                        }}>
                            <Typography
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    fontSize: '13px',
                                    fontWeight: '500',
                                    letterSpacing: '0.1em',
                                    textTransform: 'uppercase',
                                    color: 'rgba(65, 77, 101, 0.85)'
                                }}>
                                Function Description
                                <IconButton onClick={() => setDropDown(!dropDown)}>
                                    <ArrowDropDown style={{
                                        transform: dropDown ? 'rotate(180deg)' : 'rotate(0deg)',
                                        transition: 'transform 0.3s ease',
                                    }} />
                                </IconButton>
                            </Typography>
                            {dropDown ? (<Typography
                                sx={{marginBottom: '15px', color: 'rgba(65, 77, 101, 0.85)', wordBreak: 'break-word'}}>
                                <span dangerouslySetInnerHTML={{__html: operatorDescription}}/>
                            </Typography>) : null}
                        </Box>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Grid item xs={4} sx={{
                        marginLeft: 'auto',
                    }}>
                        <Button
                            disabled={Object.keys(errors).length > 0}
                            variant="contained"
                            onClick={saveTransformation}
                            sx={{
                                width: '100%',
                            }}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FlowTransformation;
